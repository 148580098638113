exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("./../../../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-generic-js": () => import("./../../../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musu-veiklos-istorijos-js": () => import("./../../../src/pages/musu-veiklos-istorijos.js" /* webpackChunkName: "component---src-pages-musu-veiklos-istorijos-js" */),
  "component---src-pages-paslaugos-js": () => import("./../../../src/pages/paslaugos.js" /* webpackChunkName: "component---src-pages-paslaugos-js" */),
  "component---src-pages-sample-js": () => import("./../../../src/pages/sample.js" /* webpackChunkName: "component---src-pages-sample-js" */),
  "component---src-pages-savanoryste-js": () => import("./../../../src/pages/savanoryste.js" /* webpackChunkName: "component---src-pages-savanoryste-js" */)
}

